.product-names {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.product-container {
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: bold;
  color: #000;
  text-align: center;
  width: 100px; /* Assure une largeur cohérente pour chaque conteneur */
}

.product-container.gaz {
  background-color: #00c0ef; /* Bleu clair */
  color: aliceblue;
}

.product-container.essence {
  background-color: #00a65a; /* Vert */
  color: aliceblue;
}

.product-container.diesel {
  background-color: #f39c12; /* Jaune */
  color: aliceblue;
}

.product-container.kerosene {
  background-color: #dd4b39; /* Rouge */
  color: aliceblue;
}
